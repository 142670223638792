import {Children, cloneElement} from 'react';

import {twMerge} from '@/stylesheets/twMerge';

export const THRESHOLD_OPTIONS = [1, 2, 3, 4, 5, 6] as const;

interface AutoGridProps {
  children: any;
  className?: string;
  cellClass?: string;
  threshold?: (typeof THRESHOLD_OPTIONS)[number];
}

interface AutoGridCellProps {
  children: any;
  className?: string;
  widthClass?: string;
  style?: any;
}

const Cell = ({children, className, widthClass, style}: AutoGridCellProps) => (
  <div className={twMerge(widthClass, className)} style={style}>
    {children}
  </div>
);

/**
 * @deprecated
 */
function AutoGrid({children, className, cellClass, threshold}: AutoGridProps) {
  const widthClasses = [
    'md:basis-full',
    'md:basis-1/2-gutter',
    'md:basis-1/3-gutter',
    'md:basis-1/4-gutter',
    'md:basis-1/5-gutter',
    'md:basis-1/6-gutter',
  ];
  const numChildren = Children.toArray(children).length;
  const numCols = threshold || (numChildren > 6 ? 6 : numChildren);
  const widthClass = widthClasses[numCols - 1];

  return (
    <div className={twMerge('flex flex-wrap default:gap-x-gutter', className)}>
      {Children.toArray(children).map((child, index) =>
        typeof child === 'object' && 'type' in child && child.type === Cell ? (
          cloneElement(child, {widthClass, className: cellClass})
        ) : (
          <div
            className={twMerge('basis-full', cellClass, widthClass)}
            key={`cell-${index.toString()}`}
          >
            {child}
          </div>
        ),
      )}
    </div>
  );
}

AutoGrid.Cell = Cell;

export default AutoGrid;
